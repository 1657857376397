var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoggedIn)?_c('div',{staticClass:"main"},[_c('NavBar'),_c('v-data-table',{key:_vm.allProducts.id,staticClass:"tableWrapper",attrs:{"page":_vm.page + 1,"pageCount":_vm.numberOfPages,"server-items-length":_vm.totalProducts,"options":_vm.options,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.allProducts,"show-expand":"","footer-props":{ 'items-per-page-options': [8, 20, 50, 100] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"parent"},[_c('div',{staticClass:"prod"},[_c('h4',[_vm._v("Opis oglasa:")]),_vm._v(" "+_vm._s(item.description)+" "),_c('h4',[_vm._v("Cena fiksna:")]),_vm._v(" "+_vm._s(item.isPriceFixed == true ? "Da" : "Ne")+" "),_c('h4',[_vm._v("Zamena:")]),_vm._v(" "+_vm._s(item.isExchangePossible == true ? "Moguća zamena" : "Bez zamene")+" ")]),_c('div',{staticClass:"dBlock"},[_c('h4',[_vm._v("Podaci prodavca:")]),_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)),_c('br'),_vm._v(" "+_vm._s(item.user.email)+" "),_c('br'),_vm._v(" "+_vm._s(item.phoneNumber)+" "),_c('h4',[_vm._v("Mogućnost plaćanja:")]),_vm._v(" "+_vm._s(item.paymentOption == 0 ? "Postoji cena" : item.paymentOption == 1 ? "Dogovor" : item.paymentOption == 2 ? "Kontakt" : item.paymentOption == 3 ? "Pozvati" : "Besplatno")+" "),_c('h4',[_c('a',{attrs:{"href":("https:/www.poljopijaca.rs/oglas/" + (item.id))}},[_vm._v(" Prikaži oglas ")])])])])])]}},{key:"item.mainImgName",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":item.mainImgName ? ("https://api.poljopijaca.rs/" + (item.mainImgName)) : require('../../../Assets/noimg.webp')}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Oglasi")])],1)]},proxy:true}],null,false,2608580643)}),_c('v-snackbar',{attrs:{"color":"green","bottom":""},model:{value:(_vm.productDeletedSnackbar),callback:function ($$v) {_vm.productDeletedSnackbar=$$v},expression:"productDeletedSnackbar"}},[_vm._v(" Oglas je uspešno obrisan ")]),_c('DeleteProduct',{attrs:{"product":_vm.deleteProduct},on:{"productIsDeleted":_vm.productIsDeleted},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }